import client from '@config/client'
import API_URL from '@config/services'

const onGetAllProfiles = payload =>
  client
    .get(API_URL.ORG_USER.GET_ALL_ORG_USERS(), payload)
    .then(response => response)
    .catch(error => error.response)

export const onInviteMember = (orgId, teamId, payload) => {
  return client
    .post(API_URL.ORG_USER.GET_ORG_USER_PK(orgId) + `?team=${teamId}`, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const onUpdateMember = (userId, payload, headers = {}) => {
  return client
    .patch(API_URL.ORG_USER.UPDATE_ORG_USER(userId), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

export const onDeleteMember = id => {
  return client
    .delete(API_URL.ORG_USER.DELETE_ORG_USER(id))
    .then(response => response)
    .catch(error => error.response)
}

export const onCreateTeam = (orgId, payload) => {
  return client
    .post(API_URL.TEAMS.POST_TEAMS_LIST(orgId), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onUpdateTeamInfo = (teamId, payload) => {
  return client
    .patch(API_URL.TEAMS.PATCH_TEAM(teamId), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const addUserToTeam = (orgId, payload) => {
  return client
    .post(API_URL.TEAMS.ADD_ORGUSER_TO_TEAM(orgId), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const patchTeamsUsers = (id, payload) => {
  return client
    .patch(API_URL.TEAMS.PATCH_TEAM_USERS(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const removeUserFromTeam = (orgId, payload) => {
  return client
    .delete(API_URL.TEAMS.REMOVE_ORGUSER_FROM_TEAM(orgId), payload)
    .then(response => response)
    .catch(error => error.response)
}

/* Orgusers */

export const createUser = (orgId, payload) => {
  return client
    .post(API_URL.ORG_USER.POST_ORGUSER_LIST(orgId), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const getTeam = teamPk => {
  return client
    .get(API_URL.TEAMS.GET_TEAM(teamPk))
    .then(response => response)
    .catch(error => error.response)
}

export default onGetAllProfiles
