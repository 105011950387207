import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  // menu: {
  //   position: 'absolute',
  //   top: '2rem',
  //   right: 0,
  //   marginTop: theme.spacing(2)
  // },
  menuTitle: {
    color: '#004068',
    paddingLeft: theme.spacing(1),
    fontWeight: 500
  },
  menuContent: {
    color: '#004068',
    paddingLeft: theme.spacing(1)
  },
  menuContentList: {
    color: '#004068',
    paddingTop: 0
  },
  menuSelected: {
    borderLeft: '4px solid #004068',
    fontWeight: '700'
  },
  menuIcon: {
    marginRight: '0.5rem'
  }
}))
