import { useTranslation } from '@contexts/translation'
import { Typography } from '@mui/material'

const NoPermissionPage = () => {
  const { dictionary } = useTranslation()

  return (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        variant="h5"
        data-testid="Typography-1fb3e30e-8c22-4e95-8ec4-7d391fc25183"
      >
        {dictionary.no_page_permission}
      </Typography>
    </div>
  )
}

export default NoPermissionPage
