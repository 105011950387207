const queryString = params =>
  Object.keys(params)
    .map(key => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map(
            value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&')
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    })
    .join('&')

export default queryString
