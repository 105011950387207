import Cookies from 'js-cookie'
import axios from 'axios'

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    post: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }
})

// You can intercept requests or responses before they are handled by then or catch.
client.interceptors.request.use(
  config => {
    const token = Cookies.get('accessToken')
    if (token) {
      config.headers.common.Authorization = `JWT ${token}`
    }
    return config
  },
  error =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
)

export default client
