import client from '@config/client'
import API_URL from '@config/services'

export const updateProfile = payload => {
  return client
    .patch(API_URL.PROFILES.GET_PROFILES_PROFILE, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateUser = payload => {
  return client
    .patch(API_URL.PROFILES.PATCH_PROFILES_USER, { ...payload })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const activate2fa = (payload = {}) => {
  return client
    .post(API_URL.PROFILES.POST_ACTIVATE_2FA, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deactivate2fa = (payload = {}) => {
  return client
    .post(API_URL.PROFILES.POST_DISABLE_2FA, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const verify2fa = (payload = {}) => {
  return client
    .post(API_URL.PROFILES.POST_VERIFY_2FA, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const unverify2fa = () => {
  return client
    .post(API_URL.PROFILES.POST_UNVERIFY_2FA)
    .then(response => response)
    .catch(error => error.response)
}

export const obtain2faToken = () => {
  return client
    .post(API_URL.PROFILES.POST_OBTAIN_TOKEN)
    .then(response => response)
    .catch(error => error.response)
}

export const inviteUser = (email, otherParams = {}) => {
  return client
    .post(API_URL.PROFILES.POST_INVITE_USER, { email, ...otherParams })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
