import client from '@config/client'
import API_URL from '@config/services'

export const onGetOperation = operationId => {
  return client
    .get(API_URL.PROJECTS.GET_PROJECT_PK(operationId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const getOperationDetails = operationId => {
  return client
    .get(API_URL.PROJECTS.GET_PROJECT_PK(operationId))
    .then(response => response.data)
    .catch(error => {
      return error.response
    })
}

export const onGetOperationLeaseArticles = operationId => {
  return client
    .get(API_URL.PROJECTS.GET_PROJECT_LEASE_ARTICLES(operationId))
    .then(response => response?.data?.results || [])
    .catch(error => {
      return error.response
    })
}

export const updateOperation = (operationId, payload) => {
  return client
    .patch(API_URL.PROJECTS.GET_PROJECT_PK(operationId), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onPostProjectUser = payload => {
  return client
    .post(API_URL.PROJECTS.POST_PROJECT_USERS, payload)
    .then(response => response)
    .catch(error => error.response)
}
