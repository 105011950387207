import routes from '@config/routes'

const NavSubMenu = {
  setup: [],
  dashboard: [],
  opportunities: [
    {
      id: 'opportunities_summary',
      display_name: 'summary',
      link: routes.opportunities.summary
    },
    {
      id: 'opportunities_opportunities',
      display_name: 'opportunities',
      link: routes.opportunities.root,
      exactURL: routes.opportunities.root
    },
    {
      id: 'opportunities_proposals',
      display_name: 'proposals_submenu',
      link: routes.opportunities.proposals
    }
  ],
  projects: [
    {
      id: 'projects_summary',
      display_name: 'summary',
      link: routes.projects.summary
    },
    {
      id: 'projects_projects',
      display_name: 'projects',
      link: routes.projects.root,
      exactURL: routes.projects.root
    }
  ],
  'Expense Reports': [],
  costs: [
    {
      id: 'costs_summary',
      display_name: 'summary',
      link: routes.costs.summary
    },
    {
      id: 'costs_suppliers',
      display_name: 'suppliers',
      link: routes.costs.suppliers
    },
    {
      id: 'costs_expenses',
      display_name: 'expenses',
      link: routes.costs.expenses
    },
    {
      id: 'costs_contractors',
      display_name: 'contracts_',
      link: routes.costs.contracts
    }
    // {
    //   id: 'costs_estimates',
    //   display_name: 'estimates',
    //   link: routes.costs.estimates
    // },
    // {
    //   id: 'costs_myexpenses',
    //   display_name: 'my_expenses',
    //   link: routes.costs.my_expenses
    // },
    // {
    //   id: 'costs_expense_items',
    //   display_name: 'expense_items',
    //   link: routes.costs.expense_items
    // },
    // {
    //   id: 'costs_payroll',
    //   display_name: 'payroll',
    //   link: routes.costs.payroll
    // },
    // {
    //   id: 'costs_overhead',
    //   display_name: 'overhead',
    //   link: routes.costs.overhead
    // }
  ],
  invoices: [
    {
      id: 'invoices_summary',
      display_name: 'summary',
      link: routes.invoicing.summary
    },
    {
      id: 'invoices_planning',
      display_name: 'planning',
      link: routes.invoicing.planning.root
    },
    {
      id: 'invoices_invoices',
      display_name: 'invoices',
      link: routes.invoicing.invoices
    },
    {
      id: 'invoices_payments',
      display_name: 'payments',
      link: routes.invoicing.payments
    },
    // {
    //   id: 'invoices_clients',
    //   display_name: 'clients',
    //   link: routes.invoicing.clients
    // },
    {
      id: 'invoices_tenants',
      display_name: 'tenants',
      link: routes.invoicing.tenants
    },
    {
      id: 'invoices_exports',
      display_name: 'exports',
      link: routes.invoicing.exports
    }
  ],
  finances: [
    {
      id: 'finances_investors',
      display_name: 'investors',
      link: routes.investors.root
    },
    {
      id: 'finances_fees',
      display_name: 'fees',
      link: routes.internal.root
    }
    // {
    //   id: 'finances_summary',
    //   display_name: 'summary',
    //   link: routes.finances.summary
    // },
    // {
    //   id: 'finances_revenue',
    //   display_name: 'revenue',
    //   link: routes.finances.revenue
    // },
    // {
    //   id: 'finances_costs',
    //   display_name: 'costsNav',
    //   link: routes.finances.costs
    // },
    // {
    //   id: 'finances_income',
    //   display_name: 'income',
    //   link: routes.finances.income
    // }
  ],
  reports: [
    {
      id: 'reports',
      display_name: 'reports',
      link: routes.reports.root,
      exactURL: routes.reports.root
    },
    {
      id: 'reports_scheduling',
      display_name: 'scheduling',
      link: routes.reports.scheduling
    }
  ],
  collaboration: [
    {
      id: 'collaboration_tasks',
      display_name: 'tasks',
      link: routes.collaboration.tasks,
      exactURL: routes.collaboration.tasks
    },
    {
      id: 'collaboration_calendar',
      display_name: 'calendar',
      link: routes.collaboration.calendar,
      exactURL: routes.collaboration.calendar
    },
    {
      id: 'collaboration_blog',
      display_name: 'blog',
      link: routes.collaboration.blog,
      exactURL: routes.collaboration.blog
    },
    {
      id: 'collaboration_notifications',
      display_name: 'notifications',
      link: routes.collaboration.notifications,
      exactURL: routes.collaboration.notifications
    }
  ],
  tasks: [],
  notes: [],
  contacts: [],
  blog: [],
  notifications: []
}
export default NavSubMenu
