import {
  format as formatDateFNS,
  formatDistance as formatDistanceDateFNS
} from 'date-fns'
import { fr, enUS } from 'date-fns/locale'

export const format = (date, formatType = 'dd-MM-yyyy', lang = 'en') => {
  /* 
  The Date object has to be in format : YYYY MM DD
  */
  if (new Date(date).toDateString() === 'Invalid Date') {
    return 'Invalid Date'
  }
  const loc = lang === 'fr' ? fr : enUS
  return formatDateFNS(date, formatType, { locale: loc })
}

export const formatDistance = (startDate, endDate, lang) => {
  const loc = lang === 'fr' ? fr : enUS
  return formatDistanceDateFNS(startDate, endDate, {
    addSuffix: true,
    locale: loc
  })
}
