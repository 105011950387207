import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(() => {
  return {
    containerRoot: {
      '& .MuiCollapse-wrapperInner': {
        width: '100%'
      }
    },
    contentRoot: {
      width: '100%',
      padding: 10,
      borderRadius: 3,
      color: '#FFFFFF',
      backgroundColor: '#161C24'
    },
    message: {
      padding: 0,
      fontWeight: 500
    },
    action: {
      marginRight: -4,
      '& svg': {
        width: 20,
        height: 20,
        opacity: 0.48,
        '&:hover': { opacity: 1 }
      }
    },
    info: {
      color: `#fff !important`,
      backgroundColor: `#1AB394 !important`
      // color: `${theme.palette.elements.white} !important`,
      // backgroundColor: `${theme.palette.primary.main} !important`
    },
    success: {
      color: `#fff !important`,
      backgroundColor: `#008000 !important`
      // color: `${theme.palette.elements.white} !important`,
      // backgroundColor: `${theme.palette.success.main} !important`
    },
    warning: {
      color: `#fff !important`,
      backgroundColor: `#F2BB2E !important`
      // color: `${theme.palette.elements.white} !important`,
      // backgroundColor: `${theme.palette.warning.main} !important`
    },
    error: {
      color: `#fff !important`,
      backgroundColor: `#EE0701 !important`
      // color: `${theme.palette.elements.white} !important`,
      // backgroundColor: `${theme.palette.error.main} !important`
    }
  }
})
