// When a page is created, a new parameter must be added!
const routes = {
  root: '/',
  account: {
    root: '/account',
    login: '/account/login',
    loginOTP: '/account/login/OTP',
    signup: '/account/signup',
    acceptInvitation: 'account/accept-invitation',
    resetPassword: '/account/reset-password',
    activation: '/account/activation/',
    email: '/account/email',
    onboarding: '/account/onboarding',
    registration: '/account/registration',
    company: '/account/company',
    domainExists: '/account/domain-exists',
    profile: {
      root: '/account/profile',
      info: '/account/profile/info',
      security: '/account/profile/security',
      password: '/account/profile/password',
      notifications: '/account/profile/notifications',
      twoFactor: '/account/profile/two-factor-auth'
    },
    settings: {
      root: '/account/settings',
      general: {
        root: '/account/settings/general',
        info: '/account/settings/general/info',
        organization: '/account/settings/general/organization',
        manager_rules: '/account/settings/general/manager_rules',
        styleguides: '/account/settings/general/styleguides',
        team: '/account/settings/general/team',
        currencies: '/account/settings/general/currencies',
        customfields: '/account/settings/general/customfields',
        tags: '/account/settings/general/tags',
        goals: '/account/settings/general/goals',
        expenseCategories: '/account/settings/general/expense-categories'
      },
      projects: {
        root: '/account/settings/projects',
        general: '/account/settings/projects/general',
        defaultPhases: '/account/settings/projects/default-phases',
        documentsTemplates: '/account/settings/projects/documents-templates'
      },
      invoicing: {
        root: '/account/settings/invoicing',
        emails: '/account/settings/invoicing/emails',
        summary: '/account/settings/invoicing/summary',
        setup: '/account/settings/invoicing/setup',
        banks: '/account/settings/invoicing/banks',
        billingEntities: '/account/settings/invoicing/billing-entities',
        invoicesSuggestion: '/account/settings/invoicing/invoices-suggestions',
        invoicingNewsLetter: '/account/settings/invoicing/invoicing-newsletter',
        forecastRules: '/account/settings/invoicing/forecast-rules',
        financialYear: '/account/settings/invoicing/financial-year',
        integrations: '/account/settings/invoicing/integrations',
        taxes: '/account/settings/invoicing/taxes',
        emailTemplates: '/account/settings/invoicing/email-templates',
        smtp: '/account/settings/invoicing/smtp'
      },
      time: {
        root: '/account/settings/time',
        timeTypes: '/account/settings/time/time-types',
        timeoffTypes: '/account/settings/time/timeoff-types',
        users: '/account/settings/time/users',
        settings: '/account/settings/time/settings',
        holidays: '/account/settings/time/holidays',
        timeEntryReminders: '/account/settings/time/time-entry-reminders',
        weekStructure: '/account/settings/time/week-structure'
      },
      team: {
        root: '/account/settings/team',
        expenses: '/account/settings/team/expenses',
        roles: '/account/settings/team/roles',
        validationSettings: '/account/settings/team/validation-settings'
      },
      modules: {
        root: '/account/settings/modules'
      }
    },
    data: {
      root: '/account/data',
      exports: '/account/data/exports',
      usage: '/account/data/usage',
      imports: '/account/data/imports',
      importsDetails: '/account/data/imports/details',
      actions: '/account/data/actions',
      inboundEmails: '/account/data/inbound-emails'
    },
    users: {
      root: '/account/users',
      users: '/account/users/users',
      invitations: '/account/users/invitations',
      permissions: '/account/users/permissions'
    },
    subscription: '/account/subscription',
    support: '/account/support'
  },

  indexes: {
    root: '/indexes'
  },
  project: {
    root: '/project',
    settings: {
      root: '/project/settings',
      details: '/project/settings/details',
      phases: '/project/settings/phases',
      client: '/project/settings/client',
      members: '/project/settings/members',
      roles: '/project/settings/roles',
      currency: '/project/settings/currency',
      invoicing: '/project/settings/invoicing',
      import: '/project/settings/import',
      export: '/project/settings/export',
      fees: '/project/settings/fees',
      progress: '/project/settings/progress',
      emails: '/project/settings/emails',
      admin: '/project/settings/admin',
      connections: '/project/settings/connections',
      info: '/project/settings/info',
      users: '/project/settings/users',
      expenses: '/project/settings/categories_expenses',
      mandates: '/project/settings/mandates',
      landlords: '/project/settings/landlords',
      lotGroups: '/project/settings/lot-groups',
      integrations: '/project/settings/integrations'
    },
    summary: '/project/summary',
    fees: {
      root: '/project/fees',
      summary: '/project/fees/summary',
      annexes: '/project/fees/annexes',
      revisions: '/project/fees/revisions',
      proposals: '/project/fees/proposals',
      ffne: '/project/fees/ffne'
    },
    timeline: {
      root: '/project/timeline',
      budget: '/project/timeline/budget',
      resources: '/project/timeline/resources'
    },
    progress: {
      root: '/project/progress',
      areas: '/project/progress/areas',
      tasks: '/project/progress/tasks',
      plans: '/project/progress/plans',
      documents: '/project/progress/documents'
    },
    invoicing: {
      root: '/project/invoicing',
      summary: '/project/invoicing/summary',
      schedule: '/project/invoicing/schedule',
      invoices: '/project/invoicing/invoices',
      planning: '/project/invoicing/planning',
      payments: '/project/invoicing/payments',
      cashflow: '/project/invoicing/cashflow',
      provider: {
        root: '/invoicing/provider',
        timeline: '/invoicing/provider/timeline',
        invoices: '/invoicing/provider/timeline/invoices'
      },
      subcontractor: {
        schedule: '/project/invoicing/subcontractor/schedule',
        estimates: '/project/invoicing/subcontractor/estimates',
        invoices: '/project/invoicing/subcontractor/invoices'
      }
    },
    costs: {
      root: '/project/costs',
      schedule: '/project/costs/schedule',
      invoices: '/project/costs/invoices',
      estimates: '/project/costs/estimates'
    },
    finance: {
      root: '/project/finance',
      investors: '/project/finance/investors',
      mortgages: '/project/finance/mortgages',
      valuation: '/project/finance/valuation',
      simulations: '/project/finance/simulations',
      lots: '/project/finance/lots'
    },
    expenses: {
      root: '/project/expenses',
      expenses: '/project/expenses/expenses',
      suppliers: '/project/expenses/suppliers',
      budgets: '/project/expenses/budgets',
      contracts: '/project/expenses/contracts'
    },
    more: {
      root: '/project/more',
      worklogs: '/project/more/worklogs',
      time: '/project/more/time',
      files: '/project/more/files',
      contacts: '/project/more/contacts',
      notes: '/project/more/notes',
      emails: '/project/more/emails',
      reports: '/project/more/reports'
    },
    reports: {
      root: '/project/reports',
      reports: '/project/reports/reports',
      scheduling: '/project/reports/scheduling',
      history: '/project/reports/history'
    },
    leases: {
      root: '/project/leases',
      leases: '/project/leases/leases',
      tenants: '/project/leases/tenants',
      timeline: '/project/leases/timeline'
    },
    lots: {
      root: '/project/lots',
      lots: '/project/lots/lots',
      meters: '/project/lots/meters'
    },
    collaboration: {
      root: '/project/collaboration/contacts',
      contacts: '/project/collaboration/contacts',
      tenants: '/project/leases/tenants',
      timeline: '/project/leases/timeline',
      tasks: '/project/collaboration/tasks',
      notifications: '/project/collaboration/notifications',
      visits: '/project/collaboration/visits',
      blog: '/project/collaboration/blog',
      files: '/project/collaboration/files'
    }
  },

  finances: {
    root: '/finances',
    summary: '/finances/summary',
    revenue: '/finances/revenue',
    costs: '/finances/costs',
    income: '/finances/income'
  },

  opportunities: {
    root: '/opportunities',
    proposals: '/opportunities/proposals',
    summary: '/opportunities/summary'
  },

  notes: {
    root: '/notes'
  },

  tasks: {
    root: '/tasks'
  },

  projects: {
    root: '/projects/summary',
    summary: '/projects/summary'
  },

  invoicing: {
    root: '/invoicing',
    summary: '/invoicing/summary',
    planning: {
      root: '/invoicing/planning',
      detail: '/invoicing/planning/detail'
    },
    invoices: '/invoicing/invoices',
    followups: '/invoicing/followups',
    payments: '/invoicing/payments',
    // clients: '/invoicing/clients',
    tenants: '/invoicing/tenants',
    revenue: '/invoicing/revenue',
    exports: '/invoicing/exports'
  },

  cashflow: {
    root: '/cashflow'
  },

  ticketing: {
    root: '/ticketing'
  },
  investors: {
    root: '/investors'
  },

  reports: {
    root: '/reports',
    scheduling: '/reports/scheduling',
    history: '/reports/history'
  },

  costs: {
    root: '/costs',
    suppliers: '/costs/suppliers',
    schedule: '/costs/schedule',
    invoices: '/costs/invoices',
    expenses: '/costs/expenses',
    my_expenses: '/costs/my-expenses',
    expense_items: '/costs/expense-items',
    payroll: '/costs/payroll',
    contracts_timeline: '/costs/contracts-timeline',
    contracts: '/costs/contracts',
    overhead: '/costs/overhead',
    estimates: '/costs/estimates',
    summary: '/costs/summary',
    exports: '/costs/exports'
  },

  collaboration: {
    root: '/collaboration',
    blog: '/collaboration/blog',
    tasks: '/collaboration/tasks',
    contacts: '/collaboration/contacts',
    notes: '/collaboration/notes',
    notifications: '/collaboration/notifications',
    calendar: '/collaboration/calendar'
  },

  validation: {
    root: '/validation',
    timeoff: '/validation/timeoff',
    recuperations: '/validation/recuperations',
    rtt: '/validation/rtt',
    expense_reports: '/validation/expense-reports',
    expenses: '/validation/expenses',
    invoices: '/validation/invoices',
    tickets: '/validation/tickets',
    cashflow: '/validation/cashflow'
  },

  landlords: {
    root: '/landlords'
  },

  contacts: {
    root: '/contacts',
    contractors: '/contacts/contractors',
    suppliers: '/contacts/suppliers',
    // clients: '/contacts/clients',
    tenants: '/contacts/tenants',
    landlords: '/contacts/landlords'
  },

  internal: {
    root: '/internal'
  },
  simulations: {
    root: '/simulations'
  },

  tenant: {
    root: '/tenant',
    operations: '/tenant/operations',
    leases: '/tenant/leases',
    invoices: '/tenant/invoices',
    payments: '/tenant/payments',
    tasks: '/tenant/tasks',
    files: '/tenant/files',
    preview: '/tenant/preview'
  },
  admin: {
    root: '/admin'
  }
}

export default routes
