import React, { createContext, useState, useEffect, useContext } from 'react'
import { getMyOrganization } from '@api/auth'
import Loader from '@components/common/Loader/Loader'

import dictionary from '../data/dictionary'
import { useAuth } from '@contexts/auth'
import { localize } from '@utils/localize'
import { useTranslation as i18useTranslation } from 'react-i18next'

const TranslationContext = createContext({})

export const TranslationProvider = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const [lang, setLang] = useState('en')
  const { t, i18n } = i18useTranslation()
  // const [country, setCountry] = useState('UK')
  // was causing tests to fail so moved to company/index
  // useEffect(() => {
  //   axios
  //     .get('https://ipapi.co/json/')
  //     .then(response => {
  //       let data = response.data
  //       setCountry(data.country_code)
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }, [])

  const country = 'FR'

  useEffect(() => {
    const getProfile = async () => {
      if (isAuthenticated) {
        const response = await getMyOrganization()

        if (response?.locale) {
          setLang(response?.locale)
        }
      } else {
        const locale = localStorage.getItem('OOTI-locale')
        if (!locale) {
          const countryCode = await localize()
          let langCode = 'en'
          if (
            countryCode == 'FR' ||
            countryCode == 'IT' ||
            countryCode == 'ES' ||
            countryCode == 'PT' ||
            countryCode == 'DE' ||
            countryCode == 'NL'
          )
            langCode = countryCode.toLowerCase()
          setLang(langCode || 'en')
        } else setLang(locale || 'en')
      }
      // LocaleManager.applyLocale('Fr');
    }
    getProfile()
  }, [isAuthenticated])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  if (!lang) return <Loader />

  return (
    <TranslationContext.Provider
      value={{ lang, setLang, dictionary: dictionary[lang], country, t }}
    >
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslation = () => useContext(TranslationContext)
