import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// import API_URL from '@config/services'
import { Grid, CircularProgress, Box } from '@mui/material'
import NotifItem from './NotifItem'
import AlertList from '@components/Notifications/AlertList'
import { useTranslation } from '@contexts/translation'
import { Close } from '@mui/icons-material'

import CommonModal from '@components/common/Modal/CommonModal'
import { useState } from 'react'
import NotifsSettingsForm from './NotifsSettings'

const NotificationsTopBar = ({
  onClose,
  notifsUnread,
  // setNotifsUnread,
  isLoadingNotifs
}) => {
  const { dictionary } = useTranslation()
  // const { enqueueSnackbar } = useSnackbar()
  // const router = useRouter()

  const [isPrefs, setIsPrefs] = useState(false)

  // const notifsURL = API_URL.DIGESTS.GET_DIGESTS_INBOX_UNREAD

  // const markNotifAsRead = async id => {
  //   setNotifsUnread && setNotifsUnread(prev => prev.filter(n => n.id !== id))
  //   const response = await markAsRead(id)

  //   responseHandler({
  //     response,
  //     callback: async () => {
  //       await mutate(notifsURL)
  //       await mutate(API_URL.DIGESTS.GET_DIGESTS_INBOX_ALL)
  //     },
  //     dictionary,
  //     snackbar: enqueueSnackbar
  //   })
  // }

  return (
    <>
      <Box
        sx={{
          bgcolor: theme => theme.palette.secondary.light,
          padding: theme => theme.spacing(2),
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="h6"
          color="secondary.dark"
          data-testid="Typography-f0dd1dda-887e-494b-aa0a-80bfa6a2859d"
        >
          {dictionary.notifications}
        </Typography>
        <IconButton
          sx={{ color: t => t.palette.secondary.dark }}
          onClick={onClose}
          data-testid="IconButton-466e5429-946f-45f4-9770-7f6fa2a87dd9"
        >
          <Close />
        </IconButton>
      </Box>

      <Grid container padding={2}>
        {/*
           <Grid item xs={12} mb={6}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              
              <Button variant="outlined" onClick={() => setIsPrefs(true)}>
                {dictionary.settings}
              </Button>
               <Button
                startIcon={<OpenInNew />}
                onClick={() => router.push(routes.collaboration.notifications)}
              >
                {dictionary.notifications_center}
              </Button>
             
            </Stack>
           </Grid>
           */}

        <Grid item xs={12}>
          <Typography
            variant="h6"
            data-testid="Typography-89f7610b-aab6-4355-8877-53d168d4f7c1"
          >
            {dictionary.alerts}
          </Typography>
          <Grid item xs={12} marginTop={2}>
            <AlertList />
            {/* <Typography variant="subtitle1">{dictionary.no_alerts}</Typography> */}
          </Grid>
          <Typography
            variant="h6"
            sx={{ mt: 3 }}
            data-testid="Typography-683dd4ec-862b-4191-85b0-ed7e21e961da"
          >
            {dictionary.notifications}
          </Typography>
          {isLoadingNotifs && (
            <Grid display="flex" justifyContent="center" sx={{ mt: 6, mb: 6 }}>
              <CircularProgress />
            </Grid>
          )}

          {!isLoadingNotifs && notifsUnread && !notifsUnread.length && (
            <Grid item xs={12} marginTop={3}>
              <Typography
                variant="subtitle1"
                data-testid="Typography-87a6e591-0f9d-48d8-a086-193fa5a298e4"
              >
                {dictionary.no_unread_notif}
              </Typography>
            </Grid>
          )}

          {!isLoadingNotifs && notifsUnread && notifsUnread.length > 0 && (
            <List dense={true} sx={{ marginTop: theme => theme.spacing(1) }}>
              {notifsUnread.map(n => (
                <NotifItem
                  key={n.id}
                  notif={n}
                  secondaryAction={
                    <IconButton
                      size="small"
                      // onClick={() => markNotifAsRead(n.id)}
                      data-testid="IconButton-cc38275e-5fb6-4d96-bd87-02edf7a47f11"
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  }
                />
              ))}
            </List>
          )}
        </Grid>
      </Grid>
      <CommonModal
        title={dictionary.notifications_preferences}
        open={isPrefs}
        onClose={() => setIsPrefs(false)}
        maxWidth="sm"
      >
        <NotifsSettingsForm onClose={() => setIsPrefs(false)} />
      </CommonModal>
    </>
  )
}

export default NotificationsTopBar
