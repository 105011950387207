import Link from 'next/link'
import Typography from '@mui/material/Typography'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import {
  Stack,
  ListItemText,
  List,
  Grid,
  Chip,
  ListItemButton,
  ListItemIcon
} from '@mui/material'
import { useTranslation } from '@contexts/translation'
import { ArrowForwardIos } from '@mui/icons-material'
import routes from '@config/routes'
import queryString from '@utils/queryString'

const Alerts = ({ items = [] }) => {
  const { dictionary } = useTranslation()

  return (
    <Stack spacing={0.5}>
      {items &&
        items.map(item => {
          const projectId = item.operation
          const itemLink =
            item.type === 'lease_expiration'
              ? `${routes.project.leases.leases}/${projectId}`
              : ''
          return (
            <Link href={itemLink} passHref key={item.name}>
              <a>
                <ListItemButton
                  sx={{
                    py: 0,
                    px: 0.5,
                    border: theme => `1px solid ${theme.palette.elements.main}`,
                    borderRadius: theme => theme.spacing(1)
                  }}
                  data-testid="ListItemButton-5f1cb0db-8d53-4519-8880-795bad40deb9"
                >
                  <ListItemText
                    primary={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Chip
                          size="small"
                          label={item.type_display || dictionary.alert}
                          sx={{
                            bgcolor: t => t.palette.error.light,
                            color: t => t.palette.error.main
                          }}
                          data-testid="Chip-41571494-0394-4686-a89a-0e0b5abd4056"
                        />

                        <Typography
                          variant="subtitle2"
                          data-testid="Typography-32acb797-1934-4728-b0f8-0c49a409c1d0"
                        >
                          {item.message}
                        </Typography>
                      </Stack>
                    }
                  />

                  <ListItemIcon sx={{ minWidth: '8px' }}>
                    <ArrowForwardIos
                      color="primary"
                      sx={{ width: 15, height: 15 }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </a>
            </Link>
          )
        })}
    </Stack>
  )
}

const AlertProjects = ({ projectId = null }) => {
  const { dictionary } = useTranslation()

  const { data: alerts } = useFetchData(
    projectId
      ? API_URL.ALERTS.GET_ALERTS() +
          '?' +
          queryString({ operation: projectId })
      : API_URL.ALERTS.GET_ALERTS()
  )

  if (!alerts?.results) {
    return (
      <Grid item xs={12}>
        <Typography
          variant="body1"
          color="text.secondary"
          data-testid="Typography-f3de80cd-edba-4244-9db1-da910223ed35"
        >
          {dictionary.no_alerts}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <List dense={true}>
          <Alerts items={alerts?.results} />
        </List>
      </Grid>
    </Grid>
  )
}

export default AlertProjects
