import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fr from './fr.json'
import en from './en.json'
import it from './it.json'
import es from './es.json'
import de from './de.json'
import nl from './nl.json'
import pt from './pt.json'

export const resources = {
  fr,
  en,
  it,
  es,
  de,
  nl,
  pt
}

// translations: https://translate.i18next.com/

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources
})

export default i18n
