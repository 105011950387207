import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(() => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
