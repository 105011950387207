import React from 'react'
import clsx from 'clsx'
import CircularProgress from '@mui/material/CircularProgress'
import useStyles from './styles'

export default function Loader({ styles }) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.container)} style={styles}>
      <CircularProgress style={styles} />
    </div>
  )
}
