import Link from 'next/link'
import { useEffect, useState } from 'react'
import {
  Chip,
  InputBase,
  List,
  ListItemButton,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material'
import { useTranslation } from '@contexts/translation'

const SelectItem = ({
  option,
  nameField,
  selectedIndex,
  idx,
  handleClickItem
}) => {
  return (
    <ListItemButton
      sx={{
        '&.Mui-selected': {
          backgroundColor: '#0062FF14',
          // backgroundColor: 'unset',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'unset'
            // backgroundColor: '#0062FF1F'
          }
        }
      }}
      selected={selectedIndex === idx}
      onClick={() => handleClickItem(option, idx)}
      data-testid="ListItemButton-f8076e50-e5ac-42a2-8bae-c52ef05f2edf"
    >
      {option[nameField]}
    </ListItemButton>
  )
}

const TopViewSelector = ({
  label = null,
  options = [],
  nameField,
  defaultIndex = 0,
  labelField = null,
  searchBar = false,
  arrow = true
}) => {
  const { dictionary } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex)
  const [value, setValue] = useState('')
  const [optionsFiltered, setOptionsFiltered] = useState([])

  // intializor only runs once... need to update state with a useEffect
  useEffect(() => {
    setSelectedIndex(defaultIndex)
  }, [defaultIndex])

  useEffect(() => {
    if (!open) {
      setValue('')
    }
    if (value.length) {
      const valueLow = value.toLowerCase()
      setOptionsFiltered(
        options.filter(
          opt =>
            typeof opt[nameField] == 'string' &&
            opt[nameField]?.toLowerCase().includes(valueLow)
        )
      )
    } else {
      setOptionsFiltered(options)
    }
  }, [value, open, options])

  const _handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const _handleClickItem = (option, idx) => {
    setSelectedIndex(idx)
    option.onClick && option.onClick()
    setAnchorEl(null)
  }

  const _handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'more-actions-popover' : undefined

  return (
    <div>
      <Chip
        clickable
        aria-describedby={id}
        onClick={_handleClick}
        sx={{
          ml: 'auto',
          background: 'unset',
          '&:hover': {
            backgroundColor: '#0062FF1F'
          }
        }}
        label={
          <Stack direction="row" alignItems="center" sx={{ color: 'white' }}>
            {label ? (
              <Typography
                paddingLeft={1}
                variant="button"
                sx={{ textTransform: 'none' }}
                data-testid="Typography-27268976-8cb1-4835-b3f0-0da44135b1c4"
              >
                {label}
              </Typography>
            ) : labelField && options[selectedIndex] ? (
              <Typography
                paddingLeft={1}
                variant="button"
                data-testid="Typography-bd10b2c0-9e76-4e95-9233-1bc69fbb16d5"
              >
                {options[selectedIndex][labelField]}
              </Typography>
            ) : nameField && options[selectedIndex] ? (
              <Typography
                paddingLeft={1}
                variant="button"
                data-testid="Typography-edefd36c-27e4-4093-8b8d-65971f37407b"
              >
                {options[selectedIndex][nameField]}
              </Typography>
            ) : (
              <Typography
                paddingLeft={1}
                variant="button"
                data-testid="Typography-3f5656d4-3e16-4eef-a352-6d67c827277f"
              ></Typography>
            )}

            {arrow &&
              (open ? (
                <ArrowDropUpIcon sx={{ color: t => `white !important` }} />
              ) : (
                <ArrowDropDownIcon sx={{ color: t => `white !important` }} />
              ))}
          </Stack>
        }
        data-testid="Chip-2532f014-2cb4-44ab-88f4-4da11a3118be"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={_handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List sx={{ minWidth: '200px' }}>
          {searchBar && (
            <InputBase
              sx={{ ml: 2, my: 1, flex: 1 }}
              placeholder={dictionary.search}
              value={value}
              onChange={e => setValue(e.target.value)}
              data-testid="InputBase-9606ba74-68e4-44bc-b564-99b141e56877"
            />
          )}

          {optionsFiltered?.map((o, i) => {
            if (!o.isHidden)
              return o.link ? (
                <Link key={i} href={o.link}>
                  <a>
                    <SelectItem
                      option={o}
                      idx={i}
                      nameField={nameField}
                      selectedIndex={selectedIndex}
                      handleClickItem={_handleClickItem}
                    />
                  </a>
                </Link>
              ) : (
                <SelectItem
                  key={i}
                  option={o}
                  idx={i}
                  nameField={nameField}
                  selectedIndex={selectedIndex}
                  handleClickItem={_handleClickItem}
                />
              )
          })}
        </List>
      </Popover>
    </div>
  )
}

export default TopViewSelector
