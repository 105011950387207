import client from '@config/client'
import API_URL from '@config/services'

export const getAdminDashboardMetrics = team_pk => {
  return client
    .get(API_URL.ORGANIZATIONS.GET_ADMIN_DASHBOARD_METRICS(team_pk))
    .then(response => response)
    .catch(error => error.response)
}

export const updateOrganization = (id, payload, headers = {}) => {
  return client
    .patch(API_URL.ORGANIZATIONS.PATCH_ORGANIZATION(id), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

export const transferOwnership = (id, payload) => {
  return client
    .patch(API_URL.ORGANIZATIONS.PATCH_TRANSFER_OWNERSHIP(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const getOrganization = orgPk => {
  return client
    .get(API_URL.ORGANIZATIONS.GET_ORGANIZATION(orgPk))
    .then(response => response)
    .catch(error => error.response)
}
