import axios from 'axios'

export const localize = () => {
  return axios
    .get('https://ipapi.co/json/')
    .then(response => {
      let data = response.data
      return data.country_code
    })
    .catch(error => {
      console.error(error)
    })
}
