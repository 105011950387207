import client from '@config/client'
import API_URL from '@config/services'
import queryString from '@utils/queryString'

/**
 * Projects
 **/

export const postFavoriteProject = (projectPk, payload) => {
  return client
    .post(API_URL.PROJECT.POST_PROJECTS_FAVORITE(projectPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

/* Top Bar */
export const opportunitiesTopBarURL = (organization, organizationTeam) =>
  API_URL.PROJECTS.GET_PROJECT_LIST_PK +
  '?' +
  queryString({
    status: 'open',
    page_size: 9999999,
    team: organizationTeam
  })

export const projectsTopBarURL =
  API_URL.PROJECTS.GET_PROJECT_LIST_PK +
  '?' +
  queryString({
    is_archived: false,
    signed: true,
    // status: ['signed', 'hold', 'completed', 'paid', 'litigious'],
    all_projects: true,
    page_size: 9999
  })
/* End Top Bar */

export const fetchProjects = () => {
  return client
    .get(API_URL.PROJECTS.GET_PROJECT_LIST_SIGNED)
    .then(response => response.data.results)
    .catch(error => error.response)
}

export const createProjects = payload => {
  return client
    .post(API_URL.PROJECTS.POST_PROJECT_LIST_PK, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const patchProjectsUsers = (id, payload) => {
  return client
    .patch(API_URL.PROJECTS.PATCH_PROJECT_USERS_PK(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteProjectsUsers = id => {
  return client
    .delete(API_URL.PROJECTS.DELETE_PROJECT_USERS_PK(id))
    .then(response => response)
    .catch(error => error.response)
}

export const deleteProject = id => {
  return client
    .delete(API_URL.PROJECTS.DELETE_PROJECT(id))
    .then(response => response)
    .catch(error => error.response)
}

export const patchProjects = (id, payload, headers = {}) => {
  return client
    .patch(API_URL.PROJECTS.PATCH_PROJECT_PK(id), payload, headers)
    .then(response => response)
    .catch(error => error.response)
}

export const resetInvoicingPlanningPk = id => {
  return client
    .post(
      API_URL.PROJECTS.POST_PROJECT_ACTIONS_PK(
        id,
        '%22reset_invoicing_planning%22'
      ),
      { reset_invoicing_planning: true }
    )
    .then(response => response)
    .catch(error => error.response)
}

export const postProjectAction = (orgPk, payload) => {
  return client
    .post(API_URL.PROJECTS.POST_PROJECT_ACTION(orgPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

/**
 * Tags
 **/

export const fetchTags = orgPk => {
  return client
    .get(API_URL.PROJECTS.GET_PROJECT_TAGS_LIST(orgPk))
    .then(response => response.data.results)
    .catch(error => error.response)
}

export const createTag = (orgPk, payload) => {
  return client
    .post(API_URL.PROJECTS.POST_PROJECT_TAGS_LIST(orgPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateTag = (id, payload) => {
  return client
    .patch(API_URL.PROJECTS.PATCH_PROJECTS_TAGS_PK(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteTag = id => {
  return client
    .delete(API_URL.PROJECTS.DELETE_PROJECTS_TAGS_PK(id))
    .then(response => response)
    .catch(error => error.response)
}

/**
 * Groups
 **/

export const getGroupByOrg = orgPk => {
  return client
    .get(API_URL.PROJECTS.GET_GROUP_BY_ORG(orgPk))
    .then(response => response)
    .catch(error => error.response)
}

export const createTagGroup = (orgId, payload) => {
  return client
    .post(API_URL.PROJECTS.POST_PROJECT_TAG_GROUPS_LIST(orgId), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const patchTagGroup = (id, payload) => {
  return client
    .patch(API_URL.PROJECTS.PATCH_PROJECT_TAG_GROUP(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteTagGroup = id => {
  return client
    .delete(API_URL.PROJECTS.DELETE_PROJECT_TAG_GROUP(id))
    .then(response => response)
    .catch(error => error.response)
}

/* Projects bulk */

export const postProjectBulk = (orgPk, payload) => {
  return client
    .post(API_URL.PROJECTS.POST_PROJECTS_USER_BULK_ADD(orgPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteProjectBulk = (orgPk, payload) => {
  return client
    .delete(API_URL.PROJECTS.DELETE_PROJECTS_USER_BULK(orgPk), payload)
    .then(response => response)
    .catch(error => error.response)
}

/* Project users */
export const postProjectUsersAction = (orgPk, teamPk, payload) => {
  return client
    .post(
      API_URL.PROJECTS.POST_PROJECT_USERS_LIST_ACTION(orgPk, teamPk),
      payload
    )
    .then(response => response)
    .catch(error => error.response)
}

export const addUser = data => {
  return client
    .post(API_URL.PROJECTS.POST_PROJECT_USERS, data)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
export const onDeleteOperationUser = pk => {
  return client
    .delete(API_URL.PROJECTS.DELETE_PROJECT_USERS_PK(pk))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
/* export const getAllUsers = id => {
  return client
    .get(API_URL.PROJECTS.GET_PROJECT_USERS(id))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
 */
