export const responseHandler = async ({
  response,
  snackbar,
  dictionary,
  callback,
  msgSuccess,
  callbackError,
  setError,
  hideErrorMsg = false,
  t // i18next dictionary
}) => {
  if (
    response?.status === 200 ||
    response?.status === 201 ||
    response?.status === 204
  ) {
    callback && (await callback(response?.data))
    msgSuccess && snackbar(msgSuccess, { variant: 'success' })
  } else if (hideErrorMsg) {
    callbackError && callbackError()
    return
  } else {
    callbackError && callbackError()

    let errMessage = ''

    if (response?.data?.errors) {
      if (setError) {
        response?.data?.errors?.forEach(e =>
          setError(e.field, { message: e.message })
        )
      }
      // errMessage = response?.data?.errors.map(e => e.message).join('\n')
      errMessage = response?.data?.errors[0]?.message
    }

    const error400msg = dictionary
      ? `${dictionary.error} - ${dictionary.bad_request}`
      : `${t('error')} - ${t('bad_request')}`

    const error401msg = dictionary
      ? `${dictionary.error} - ${dictionary.not_authorized}`
      : `${t('error')} - ${t('not_authorized')}`

    const error403msg = dictionary
      ? `${dictionary.error} - ${dictionary.permission_error}`
      : `${t('error')} - ${t('permission_error')}`

    const error404msg = dictionary
      ? `${dictionary.error} - ${dictionary.not_found}`
      : `${t('error')} - ${t('not_found')}`

    const error500msg = dictionary ? dictionary.server_error : t('server_error')

    if (snackbar) {
      if (response?.status === 400) {
        if (errMessage)
          snackbar(errMessage, {
            variant: 'warning',
            style: { whiteSpace: 'pre-wrap' },
            autoHideDuration: 5000
          })
        else snackbar(error400msg, { variant: 'warning' })
      } else if (response?.status === 401)
        if (errMessage)
          snackbar(errMessage, {
            variant: 'warning',
            style: { whiteSpace: 'pre-wrap' },
            autoHideDuration: 5000
          })
        else snackbar(error401msg, { variant: 'warning' })
      else if (response?.status === 403)
        snackbar(error403msg, { variant: 'warning' })
      else if (response?.status === 404)
        snackbar(error404msg, { variant: 'warning' })
      else if (response?.status === 503) return
      else snackbar(error500msg, { variant: 'error' })
    }
  }
}
