import client from '@config/client'
import API_URL from '@config/services'

export const getMyOrganization = () =>
  client
    .get(API_URL.PROFILES.GET_PROFILES_PROFILE)
    .then(response => response.data)
    .catch(error => error.response)

export const patchMyOrganization = payload =>
  client
    .patch(API_URL.PROFILES.GET_PROFILES_PROFILE, payload)
    .then(response => response)
    .catch(error => error.response)

export const postLogin = payload => {
  return client
    .post(API_URL.AUTH.LOGIN, payload)
    .then(response => response)
    .catch(error => error.response)
}
export const onRefreshToken = payload => {
  return client
    .post(API_URL.AUTH.TOKEN_REFRESH, {
      token: payload
    })
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const postSignUp = payload => {
  return client
    .post(API_URL.AUTH.SIGNUP, payload)
    .then(response => response)
    .catch(error => error.response)
}
export const postTokenVerify = payload => {
  return client
    .post(API_URL.AUTH.TOKEN_VERIFY, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const postActivation = payload => {
  return client
    .post(API_URL.AUTH.ACTIVATION, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const getMyProfile = () => {
  return client
    .get(API_URL.AUTH.ME)
    .then(response => response)
    .catch(error => error.response)
}

export const getOrgUser = () => {
  return client
    .get(API_URL.ORG_USER.GET_MY_ORG_USER())
    .then(response => response)
    .catch(error => error.response)
}

export const getMyPermissions = orguser => {
  let url = API_URL.PERMISSIONS.GET_PERMISSIONS_MAP
  if (orguser) {
    url += '?view_as=' + orguser
  }
  return client
    .get(url)
    .then(response => response.data)
    .catch(error => error.response)
}

export const getRefreshToken = payload => {
  return client
    .post(API_URL.AUTH.ACTIVATION, { token: payload })
    .then(response => response)
    .catch(error => error.response)
}

export const postResendVerification = payload => {
  return client
    .post(API_URL.AUTH.RESEND_VERIFICATION, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const postSetPassword = payload => {
  return client
    .post(API_URL.AUTH.SET_PASSWORD, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const postResetPassword = payload => {
  return client
    .post(API_URL.AUTH.RESET_PASSWORD, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const postResetPasswordConfirm = payload => {
  return client
    .post(API_URL.AUTH.RESET_PASSWORD_CONFIRM, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const getAllOrganizations = () => {
  return client
    .get(API_URL.ORG_USER.GET_ORGANIZATIONS)
    .then(response => response)
    .catch(error => error.response)
}

export const updateMyProfilePartial = payload => {
  return client
    .patch(API_URL.AUTH.ME, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateMyProfile = payload => {
  return client
    .put(API_URL.AUTH.ME, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const updateUserProfilePartial = (id, payload) => {
  return client
    .patch(API_URL.AUTH.PATCH_USER(id), payload)
    .then(response => response)
    .catch(error => error.response)
}
