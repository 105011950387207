import useSWR from 'swr'
import fetcher from '@lib/fetcher'
import { useRouter } from 'next/router'
import routes from '@config/routes'
import { useAuth } from '@contexts/auth'

export const useFetchData = (url, options) => {
  const router = useRouter()
  const { logout } = useAuth()
  // const { data, error } = useSWR(`${url}${id ? `${id}` : ''}`, fetcher, options)
  const { data, error } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    ...options
  })

  if (
    !router?.asPath.includes(routes.account.acceptInvitation) &&
    !router?.asPath.includes(routes.account.activation) &&
    !router?.asPath.includes(routes.account.login) &&
    !router?.asPath.includes(routes.account.onboarding) &&
    !router?.asPath.includes(routes.account.registration) &&
    !router?.asPath.includes(routes.account.resetPassword) &&
    !router?.asPath.includes(routes.account.signup) &&
    error &&
    error.status === 401
  )
    logout()

  return {
    data,
    isLoading: url != null && !error && !data,
    isError: error
  }
}
