import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },

  MobileSubmenuItems: {
    paddingLeft: '32px',
    paddingRight: '32px'
  },

  list: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    backgroundColor: theme.palette.elements.light
  },

  avatarContainer: {
    justifySelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    cursor: 'pointer',
    position: 'relative'
  },

  notifsDrawer: {
    top: theme.spacing(6),
    height: `calc(100vh - ${theme.spacing(8)})`,
    width: theme.spacing(70)
  },

  menuDrawer: {
    width: 240,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  mobTool: {
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: '10px',
    fontSize: '40px',
    transform: 'rotate(45deg)',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
    //enable if display header instead of cross button
    // visibility:'hidden'
  },
  toggleBtn: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  DeskMenu: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  MobMenu: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  menuHidden: {
    width: 0
  }
}))
