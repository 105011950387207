import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import useStyles from './styles'

const DismissAction = ({ id }) => {
  const [isSuccess, setIsSucces] = useState(false)
  const theme = useTheme()
  useEffect(() => {
    const snackbarElement = document.querySelector('.SnackbarContent-root')
    if (snackbarElement?.className?.toLocaleLowerCase().includes('success')) {
      setIsSucces(true)
    }
  }, [])
  const { closeSnackbar } = useSnackbar()
  return (
    <>
      <IconButton
        size="small"
        onClick={() => closeSnackbar(id)}
        data-testid="IconButton-9936fd20-229d-4444-98af-4265760cfa2f"
      >
        <Close
          fontSize="small"
          sx={{
            color: isSuccess ? '#FFFFFF' : theme.palette.error.main
          }}
        />
      </IconButton>
    </>
  )
}

function NotistackProvider({ children }) {
  const classes = useStyles()

  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={{
        containerRoot: classes.containerRoot,
        contentRoot: classes.contentRoot,
        message: classes.message,
        action: classes.action,
        variantInfo: classes.info,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
        variantError: classes.error
      }}
      action={id => <DismissAction id={id} />}
    >
      {children}
    </SnackbarProvider>
  )
}

export default NotistackProvider
