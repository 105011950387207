// import Link from 'next/link'
import Router from 'next/router'
import { mutate } from 'swr'
import { Grid, List, ListItem, ListItemButton } from '@mui/material'
import { Check } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import Cookies from 'js-cookie'

// import routes from '@config/routes'
import API_URL from '@config/services'
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import { patchMyOrganization } from '@api/auth'
import { responseHandler } from '@utils/responseHandler'

const HeaderActionsTable = () => {
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const { data: profile } = useFetchData(API_URL.PROFILES.GET_PROFILES_PROFILE)

  const { data: organizations } = useFetchData(
    API_URL.ORG_USER.GET_ORGANIZATIONS
  )

  const handleCompanySelection = async values => {
    const response = await patchMyOrganization({ selected_org: values.id })

    responseHandler({
      response,
      callback: async () => {
        Cookies.remove('view_as')
        Cookies.remove('view_as_display')
        await mutate(API_URL.PROFILES.GET_PROFILES_PROFILE)
        Router.reload()
      },
      dictionary,
      msgSuccess: dictionary.company_changed,
      snackbar: enqueueSnackbar
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <List disablePadding dense={false}>
          {organizations &&
            organizations.organizations.map(org => (
              <ListItem
                key={org.id}
                disablePadding
                sx={{
                  height: t => t.spacing(5),
                  border: theme => `1px solid ${theme.palette.other.stroke}`,
                  borderRadius: theme => theme.spacing(1),
                  margin: theme => `${theme.spacing(1)} 0`,
                  '&:last-child': { mb: 0 },
                  '&:first-of-type': { mt: 0 },
                  bgcolor:
                    profile && profile.selected_org === org.id
                      ? t => t.palette.primary.xtraLight
                      : ''
                }}
              >
                <ListItemButton
                  onClick={() => handleCompanySelection(org)}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  data-testid="ListItemButton-8f29b5d6-4d21-4ef2-a720-37c68209f941"
                >
                  <span data-testid="span-4f5a1ad6-80f3-492d-8052-cd48454e52c5">
                    {org.title}
                  </span>
                  {profile && profile.selected_org === org.id && (
                    <Check color="primary" />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Grid>

      {/* <Grid item xs={12} align="right">
          <Link href={routes.account.company} passHref>
            <Button startIcon={<Add />} data-cy={'add-btn'} variant="contained" onClick={handleClose}>
              {dictionary.new}
            </Button>
          </Link>
         </Grid> */}
    </Grid>
  )
}

export default HeaderActionsTable
