const has_perm = ({ perm = [], operation = null, perms = null }) => {
  let hasPerm = false

  // If a project is given in argument
  if (perm && perms && operation !== null) {
    perm.forEach(p => {
      if (
        perms.operations[operation]?.is_admin ||
        perms.operations[operation]?.permissions?.includes(p)
      ) {
        hasPerm = true
      }
    })
  }
  return hasPerm
}

const userIsAdmin = ({ org = null, perms = null }) => {
  if (perms && perms.organizations[org]) {
    return perms.organizations[org]?.is_admin
  }
  return false
}

export const checkPerms = ({
  perm = [],
  is_admin = false,
  org = null,
  team = null,
  project = null,
  perms = null,
  checkAllProjects = false
}) => {
  return true // disable perms for now

  // if (org === null || perms === null) {
  //   return false
  // }

  // if (perms['organizations'] === undefined) {
  //   return false
  // }

  // if (is_admin && !userIsAdmin({ org: org, perms: perms }) && perm === null) {
  //   return false
  // }

  // // If the user is admin, no need to check perm(s)
  // if (userIsAdmin({ org: org, perms: perms })) {
  //   return true
  // }

  // if (!perm) {
  //   return false
  // }

  // // If an org is given in argument
  // if (org !== null) {
  //   // If we have perm(s) to check && the user have any of them in the org && no team or project are given in arguments
  //   if (!has_perm({ perm: perm, org: org, perms: perms }) && project === null) {
  //     return false
  //   }
  // }

  // // If a project is given in argument
  // if (project !== null && project !== undefined) {
  //   // If we have perm(s) to check && the user have any of them in the project
  //   if (!has_perm({ perm: perm, operation: project, perms: perms })) {
  //     return false
  //   }
  // }
  // return true
}
