import { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ThemeProvider } from '@mui/material/styles'
import frLocale from 'date-fns/locale/fr'
import NotistackProvider from '@components/common/Notistack/NotistackProvider'
import Header from '@components/Header'
import { AuthProvider, ProtectRoute } from '@contexts/auth'
import { TranslationProvider } from '@contexts/translation'
import theme from '@styles/theme/theme'
import '@styles/globals.css'
import { Analytics } from '@vercel/analytics/react'
import '@data/i18n/i18n'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

if (typeof window !== 'undefined') {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    loaded: posthog => {
      // posthog.debug() // debug mode in development
    }
  })
}

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const path = router.pathname
  const siteTitle = path.split('/')
  // check if account/login or account/signup in path. if so create const isLoginOrSignup = true
  const isLoginOrSignup =
    path.includes('account/login') ||
    path.includes('account/signup') ||
    path.includes('account/reset-password') ||
    path.includes('account/registration') ||
    path.includes('account/activation')
  // path.includes('account/company')

  useEffect(() => {
    if (router.asPath.includes('undefined')) {
      router.push('/404')
    }
  }, [router])
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <PostHogProvider client={posthog}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <NotistackProvider>
          <AuthProvider>
            <TranslationProvider>
              <ProtectRoute>
                <ThemeProvider theme={theme}>
                  {!isLoginOrSignup && !router?.pathname?.includes('print') ? (
                    <Header>
                      <Head>
                        <link
                          rel="preconnect"
                          href="https://fonts.googleapis.com"
                        />
                        <link
                          rel="preconnect"
                          href="https://fonts.gstatic.com"
                          crossorigin
                        />
                        <link
                          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap"
                          rel="stylesheet"
                        />
                        <title>KEYSY {siteTitle[1]}</title>
                      </Head>
                      <Component {...pageProps} />
                    </Header>
                  ) : (
                    <>
                      <Head>
                        <title>KEYSY {siteTitle[1]}</title>
                      </Head>
                      <Component {...pageProps} />
                      <Analytics />
                    </>
                  )}
                </ThemeProvider>
              </ProtectRoute>
            </TranslationProvider>
          </AuthProvider>
        </NotistackProvider>
      </LocalizationProvider>
    </PostHogProvider>
  )
}

export default MyApp
