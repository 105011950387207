import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
// import { mutate } from 'swr'
// import { useSnackbar } from 'notistack'

import {
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material'

import { useTranslation } from '@contexts/translation'
// import { responseHandler } from '@utils/responseHandler'
// import { useAuth } from '@contexts/auth'

const NotifsSettingsForm = ({ onClose }) => {
  //   const { organization } = useAuth()
  const { dictionary } = useTranslation()
  //   const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      filter: 'all'
    }
  })

  const onSubmit = async () => {
    setLoading(true)

    // values.action = 'link_to_contact'
    // values.pk_list = contactSelected.map(c => c.id)
    // values.related = +values.related

    // const response = await postContactAction(organization, values)

    // responseHandler({
    //   response,
    //   callback: async () => await mutate(contactsURL),
    //   dictionary,
    //   msgSuccess: dictionary.contact_linked,
    //   snackbar: enqueueSnackbar
    // })

    setLoading(false)
    onClose && onClose()
  }

  return (
    <Grid item xs={12}>
      <form data-cy={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="filter"
              control={control}
              defaultValue="all"
              render={({ field }) => (
                <RadioGroup
                  aria-label="type_aria_label"
                  defaultValue="all"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                  onChange={e => field.onChange(e.target.defaultValue)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        data-testid="Typography-1c5d0f25-3b1a-44ac-a220-61dd5d058246"
                      >
                        {dictionary.notify_me}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label={
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            data-testid="Typography-0cebddbc-3d31-4736-af77-cb5d03c0d1c5"
                          >
                            {dictionary.all_notifications || ''}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="only_me"
                        control={<Radio size="small" />}
                        label={
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            data-testid="Typography-28e00996-3eaf-410f-95ed-c44a6b2ae8d7"
                          >
                            {dictionary.only_alert_notif_me || ''}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="only_alerts"
                        control={<Radio size="small" />}
                        label={
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            data-testid="Typography-e3ef9815-86d9-47bd-a395-024cf61a5d40"
                          >
                            {dictionary.only_alerts || ''}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="sound_effect"
              control={control}
              defaultValue="all"
              render={({ field }) => (
                <RadioGroup
                  aria-label="type_aria_label"
                  defaultValue="all"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                  onChange={e => field.onChange(e.target.defaultValue)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        data-testid="Typography-9056b4e9-5124-426b-b35a-44aa2a7ce9cb"
                      >
                        {dictionary.sound_effect_for}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label={
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            data-testid="Typography-a6fc0bb4-1024-4200-9ea9-18eb37e70dcb"
                          >
                            {dictionary.all_notifications || ''}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="only_alerts"
                        control={<Radio size="small" />}
                        label={
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            data-testid="Typography-f09b9f27-8c1b-4af7-aa19-ee81add8e134"
                          >
                            {dictionary.only_alerts || ''}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="deactivate"
                        control={<Radio size="small" />}
                        label={
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            data-testid="Typography-22b4324d-4d22-4ca8-86c6-a39f4f45181a"
                          >
                            {dictionary.deactivate || ''}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            />
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Button
              type="submit"
              color="error"
              variant="outlined"
              onClick={onClose}
              data-testid="Button-01e619d4-fc6f-401c-8047-1a6a01078f9c"
            >
              {dictionary.cancel}
            </Button>
            <Button
              type="submit"
              disabled={loading}
              data-testid="Button-f0cc1042-a42b-4a42-9a8e-ba6637513847"
            >
              {dictionary.save}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default NotifsSettingsForm
